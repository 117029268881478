import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Section, Text, Title } from "../components/Core"
import { StaticImage } from "gatsby-plugin-image"

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const HiddenText = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  margin-top: 10px;

`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="Cosmetic Procedures" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);



const SaveFacePage = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return(
  
  <>
    {/* <!-- Content section 2 --> */}
    <Separator />
    <Section bg="#f7f7fb" py={4} >
    <Container className="pt-4 pb-4"    data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
          <Row className="align-items-center">
            <Col md="9" lg="12" className="">
      
                   <Iwrap>


<Itext as="h2">Save<span>Face</span></Itext>
</Iwrap>
      <SecondText>
 You safety matters
      </SecondText>


            
     
            </Col>
            </ Row>
            </Container> <br />
       
      <Container className=" mt-lg-2 mb-lg-2">
    

          <Row >
            <Col className="mb-4" lg="6" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="150">
              <StaticImage
                src="../assets/image/jpeg/save.jpg"
                formats={["auto", "webp", "aif"]}
                alt="Dermmaina Clinic Of Excellence"
                placeholder="blurred"
                layout="constrained"
                quality={100}
                width={624}
                className="rounded shdow"
              />
            </Col> <br /> <br />
              <Col  lg="6" className="mb-4 lg-pr-3 order-lg-1" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="150">
           

      <Box className=" pt-lg-0">
          
          <Text>

At Dermamina we have successfully attained the Save Face Accreditation, which signifies our consistent adherence to the highest industry standards. To earn this esteemed recognition, we underwent a rigorous evaluation conducted by an independent body. <br /> <br />
{' '}
     <span style={{ cursor: 'pointer', color: '#006B7B' , fontSize: '12px' }} onClick={toggleVisibility}>
          {isVisible ? 'Show less' : 'Read more'}
        </span>
      </Text>
      <HiddenText isVisible={isVisible}>
        <Text>
        Save Face is a register accredited by the Professional Standards Authority, holding official recognition from the Government, the Department of Health, and NHS England. This acknowledgment underscores our unwavering commitment to delivering exceptional quality services, meeting the stringent criteria set by these esteemed organizations.


            </Text>
</HiddenText>


          
       
        </Box>
              </Col>
         
     
         
        </Row>
      </Container>
    </Section>
    <SeparatorEnd />
  </>
);
  };

export default SaveFacePage;








